import Vue from 'vue'
import Vuex from 'vuex'
import { post } from './../common/request'

// 模块
import carModule from './car'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: {},
    /** 平台信息 */
    webInfo: {},
    /** IM加入状态 */
    imIsReady: false,
  },
  mutations: {
    /**
     * 改变IM载入状态
     */
    imReadyStatusChange(state, val) { state.imIsReady = val },
    /**
     * 初始化用户信息
     */
    init(state) {
      var tk = localStorage.getItem('token')
      var ui = localStorage.getItem('user_info')
      state.token = tk || '';
      state.userInfo = JSON.parse(ui) || {};
      if (tk) this.dispatch('getCarNum');
    },
    /**
     * 保存token
     */
    saveToken(state, val) {
      state.token = val;
      localStorage.setItem('token', val)
    },
    /**
     * 保存userInfo
     */
    saveUserInfo(state, val) {
      state.userInfo = val;
      localStorage.setItem('user_info', JSON.stringify(val))
    },
    /**
     * 退出
     */
    loginOut(state) {
      state.token = '';
      state.userInfo = {};
      localStorage.removeItem('token');
      localStorage.removeItem('user_info');
      this.commit('updateCarNum');
    }
  },
  getters: {
  },
  actions: {
    /** 网站信息 */
    async getWebInfo({ state }) {
      var res = await post('60e561cd51d26');
      state.webInfo = res.data || {};
      // console.debug('网站信息', res.data)
    }
  },
  modules: {
    car: carModule
  }
})
