import axios from 'axios'
const apiFix = 'm2227';
import configInfo from './config'
import store from '@/store'
import router from '@/router'
let loginOutTimerObj = null;
// 请求拦截
axios.interceptors.request.use(function (config) {
  var tk = localStorage.getItem('token')
  if (tk) config.headers['user-token'] = tk;
  // 微信登录绑定手机号特殊处理【start】
  else if (config.url == '63e22f779ffee') {
    var stk = sessionStorage.getItem('tempUserToken');
    if (stk) config.headers['user-token'] = stk;
  }
  // 微信登录绑定手机号特殊处理【end】
  config.baseURL = configInfo.apiBase;
  config.url = `/api/${apiFix}/${config.url}`
  return config;
}, function (err) {
  return Promise.reject((err || {}).data)
})
axios.interceptors.response.use(function (res) {
  if (res.data.code == 1) return res.data;
  else if (res.data.code == "-201" || res.data.code == "-203" || res.data.code == "-202") {
    clearTimeout(loginOutTimerObj)
    loginOutTimerObj = setTimeout(() => {
      store.commit('loginOut');
      router.replace('/home');
    }, 500);
  }
  return Promise.reject(res.data);
}, function (err) {
  return Promise.reject((err || {}).data)
})

// 导出请求
export function post(url, data) {
  return axios({
    method: 'post',
    url,
    data
  })
}