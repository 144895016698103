/**
 * 购物车逻辑
 */
import { post } from '@/common/request'
export default {
    state: {
        carNum: 0,
    },
    mutations: {
        updateCarNum(state, num = 0) { state.carNum = num; },
    },
    actions: {
        async getCarNum({ commit }) {
            var res = await post('63c512227b22a')
            commit('updateCarNum', res.data.length || 0)
        }
    }
}